import "./App.css";
import { Link } from "react-scroll";
import { useMemo, useState, useCallback } from "react";
import { ethers } from "ethers";
import { useContractFunction, useEthers } from "@usedapp/core";
import { nftABI } from "./nftABI";
import { useSaleId } from "./hooks/useSaleId";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoImg from "./img/logo_white.svg";

function App() {
  const { account, activateBrowserWallet, chainId } = useEthers();
  const [amount, setAmount] = useState(1);
  const salePrice = useMemo(() => {
    if (amount < 1) {
      return 0;
    }
    if (amount >= 10) {
      return ethers.utils.parseEther("0.045").mul(amount);
    }
    return ethers.utils.parseEther("0.05").mul(amount);
  }, [amount]);

  const nft = new ethers.Contract(
    process.env.REACT_APP_NFT,
    new ethers.utils.Interface(nftABI)
  );
  const { send: mint, state: mintState } = useContractFunction(nft, "mint");

  const saleId = useSaleId(process.env.REACT_APP_NFT);
  const remainingAmount = !!saleId ? 9871 - saleId.toNumber() : "";

  const etherScanUrl =
    process.env.REACT_APP_ID === "80001"
      ? `https://mumbai.polygonscan.com/address/${process.env.REACT_APP_NFT}`
      : `https://etherscan.io/address/${process.env.REACT_APP_NFT}`;
  const openSeaUrl =
    process.env.REACT_APP_ID === "80001"
      ? `https://testnets.opensea.io/collection/test-2ageeehoj8`
      : `https://opensea.io/collection/mona-flower`;

  const purchaseButton = useCallback(() => {
    if (
      chainId !== undefined &&
      chainId.toString() !== process.env.REACT_APP_ID
    ) {
      return (
        <>
          <button disabled className="button button-purchase">
            PURCHASE
          </button>
          <div className="button-error-message">
            <span>Switch network to mainnet</span>
          </div>
        </>
      );
    }
    if (mintState.status === "None") {
      return (
        <button
          className="button button-purchase"
          onClick={() => mint(amount, { value: salePrice.toString() })}
        >
          PURCHASE
        </button>
      );
    } else if (mintState.status === "Mining") {
      return (
        <button className="button button-purchase" disabled>
          Sending transaction...⏳
        </button>
      );
    } else if (!!mintState.errorMessage) {
      return (
        <>
          <button
            className="button button-error"
            onClick={() => {
              window.location.reload();
            }}
          >
            ERROR <FontAwesomeIcon icon={faRotateRight} />
          </button>
          <div className="button-error-message">
            Please reload and try again
          </div>
        </>
      );
    } else if (mintState.status === "Success") {
      return (
        <>
          <div className="button-success-message">
            <span>SUCCESS</span>
          </div>
          <button
            className="button button-success"
            onClick={() => {
              window.open(
                process.env.REACT_APP_ID === "80001"
                  ? `https://testnets.opensea.io/assets/mumbai/${
                      process.env.REACT_APP_NFT
                    }/${mintState.receipt?.events[1].args.tokenId.toNumber()}`
                  : `https://opensea.io/assets/${
                      process.env.REACT_APP_NFT
                    }/${mintState.receipt?.events[0].args.tokenId.toNumber()}`
              );
            }}
          >
            Opensea <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
          </button>
          <div className="button-success-message">
            <a
              href={
                process.env.REACT_APP_ID === "80001"
                  ? `https://mumbai.polygonscan.com/tx/${mintState.transaction.hash}`
                  : `https://etherscan.io/tx/${mintState.transaction.hash}`
              }
              target="_blank"
              rel="noreferrer noopener"
            >
              Etherscan
            </a>
          </div>
        </>
      );
    } else {
      return (
        <button className="button button-purchase" disabled>
          ⏳
        </button>
      );
    }
  }, [mint, mintState, chainId, amount, salePrice]);

  const onClickName = () => {
    alert("sage");
  };

  const tweetURL = useMemo(() => {
    const lines = [
      "（　´∀｀）＜ #MONAFlower #NFTGiveaway",
      "0x0000000000000000000000000000000000000000",
      window.location.href,
    ];
    return `https://twitter.com/intent/tweet?text=${lines
      .map((line) => encodeURIComponent(line))
      .join("%0a")}`;
  }, []);

  const onTweetButtonClick = useCallback(() => {
    if (account === undefined) {
      window.open(tweetURL, "_blank");
    } else {
      window.open(
        tweetURL.replace(
          encodeURIComponent("0x0000000000000000000000000000000000000000"),
          account
        ),
        "_blank"
      );
    }
  }, [account, tweetURL]);

  return (
    <main>
      <div className="container">
        <a href="/">
          <h1 className="page-title">MONA Flower NFT</h1>
        </a>{" "}
        <Link to="purchase" smooth={true} duration={600}>
          <span className="jump-link" style={{ marginRight: 16 }}>
            ▼︎Purchase▼
          </span>
        </Link>{" "}
        <Link to="giveaway" smooth={true} duration={600}>
          <span className="jump-link">▼︎Giveaway▼</span>
        </Link>
        <hr className="border-hr" />
        <h2 className="title">
          【MONA】Thread to talk about MONA Flower【NFT】
        </h2>
        <dl id="1">
          <dt className="item">
            1 ：<span className="name">2chpool◆bzJIFpVqts</span>
            ：2022/3/1(火) 07:52:58
            <small>.13</small> <span>ID:mnc0inFlw0</span>{" "}
            <span className="count">[1/4]</span>
          </dt>
          <dd>
            <p>
              <img src="/img/mona.svg" alt="mona" />
              <br />
              <br />
              10,000 unique NFTs by enthusiastic Monacoin lovers on the
              Ethereum.
            </p>
            <p>
              The Story
              <br />
              <br />
              In early 2014, Monacoin made its debut on 2ch.net, a famous
              internet bbs in Japan. Although other PoW cryptocurrencies
              existed, but Mr. Watanabe, who was also known as GOD in Japanese
              computer programers, modified the first cryptocurrency in Japan.
              He launched Monacoin in just 8 days. Now, inspired by the famous
              and cool BTC Flower by Ludo on ICP, MONA Flower is born!
            </p>
            <p>
              This story cuts closer to the ideals of open source and
              inspiration than most realize. Monacoin started in internet with
              small community, but the first cryptocurrency in Japan the world
              has ever known. Egalitarian in nature, it has no owner and is free
              for everyone — Mr. Watanabe’s gift to the world. This was only the
              beginning. Mr. Watanabe probably went on to stay involved in the
              space throughout crypto winter. His DNA, or rather enthusiastic
              Monacoin lovers considered MONA Flower in February 2022, remaining
              a symbol of hope in the market’s darkest days.
            </p>
            <p>
              The next iteration of MONA Flower is coming in the form of 10,000
              digital NFTs, commemorating the homage to Monacoin. They will
              exist on Ethereum, not Monacoin, because Ethereum is popular — but
              too expensive on gas fee. The art itself will be a true inspired
              homage, a single piece of art in iterable and varied forms,
              distributed as a network species.
            </p>
            <p>
              The MONA Flower is more than just an artistic representation or
              symbol for the Monacoin movement — it’s a distillation of
              Monacoin’s story that reminds us where we’ve been, where we’re
              going, and most importantly, why we Monacoiners do what we do.
            </p>
            <p>
              Frequently asked questions, See{" "}
              <Link to="2" smooth={true} duration={600}>
                <span className="jump-link">&gt;&gt;2</span>
              </Link>
            </p>
          </dd>
          <br />
        </dl>
        <dl id="2">
          <dt className="item">
            2 ：
            <span className="name sage" onClick={onClickName}>
              2chpool◆bzJIFpVqts
            </span>
            ：2022/3/1(火) 07:54:25<small>.30</small> ID:mnc0inFlw0
            <span className="count">[2/4]</span>
          </dt>
          <dd>
            <p>◼︎What is MONA Flower?</p>
            <p>
              MONA Flower is NFT art created based Monacoin and inspired by BTC
              Flower. It exists as an assortment of 10,000 unique variations,
              the original plants that will spawn a garden commemorating
              accomplishments of the cryptosphere.
            </p>
            <br />
            <p>◼︎What are the benefits of holding MONA Flower?</p>
            <p>
              MONA Flower is a purely artistic expression that expands on the
              inspired homage Mr. Watanabe has dedicated 8 days in 2013 to
              exploring. That said, just as Monacoin was at the center of the
              crypto space in Japan it gave rise to, MONA Flower will remain at
              the heart of the garden Mr. Watanabe and community plans to
              create.
            </p>
            <br />
            <p>◼︎What makes a MONA Flower unique?</p>
            <p>
              Each flower will have several possible textures (diamond, gold,
              silver, bronze, gray, beige) for several component parts (grave,
              flower, Monacoin).
            </p>
            <br />
            <p>◼︎Who is Mr. Watanabe?</p>
            <p>
              An unidentified person, who ran "2chpool," a mining pool for
              Litecoin and so on. On December 23, 2013, Mr. Watanabe unveiled
              Monacoin, the first cryptocurrency in Japan the world has ever
              known, and he launched Monacoin in just 8 days. Using Monacoin and
              inspired by BTC Flower, this generative art collection emerges as
              MONA Flower. You could learn more about Mr. Watanabe’s work at{" "}
              <a
                href="https://ja.wikipedia.org/wiki/Monacoin"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://ja.wikipedia.org/wiki/Monacoin
              </a>{" "}
              or{" "}
              <a
                href="https://anago.5ch.net/test/read.cgi/software/1387175623/"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://anago.5ch.net/test/read.cgi/software/1387175623/
              </a>
              .
            </p>
            <br />
            <p>◼︎What is pricing for participation?</p>
            <p>
              For the first 9,870 units, the floor price of each flower at
              launch will be 0.05 ETH and the rarity will be uniform and random.
              They have 10 WATANABE DNAs each. For the next 126 units, its
              grave, flower, and Monacoin will be same color each, and rare
              words placed on grave, and will appear in auctions on{" "}
              <a href="https://opensea.io/collection/mona-flower?search[sortAscending]=true&search[sortBy]=EXPIRATION_DATE&search[toggles][0]=ON_AUCTION">
                OpenSea
              </a>
              . They have 200 WATANABE DNAs each. For the last 4 units, its
              colors of grave, flower and Monacoin will be limited, and special
              rare words placed on grave, and will appear in auctions on{" "}
              <a href="https://opensea.io/collection/mona-flower?search[sortAscending]=true&search[sortBy]=EXPIRATION_DATE&search[toggles][0]=ON_AUCTION">
                OpenSea
              </a>{" "}
              as well. They have 10,000 WATANABE DNAs each.
            </p>
            <br />
            <p>◼︎How is rarity determined?</p>
            <p>
              For the first 9,870 units have less WATANABE DNAs and no rarity,
              the remaining 130 have more WATANABE DNAs and rarity, but each one
              is non-fungible and only one flower in the world.
            </p>
            <br />
          </dd>
        </dl>
        <dl id="purchase">
          <dt className="item">
            3 ：
            <span className="name sage" onClick={onClickName}>
              2chpool◆bzJIFpVqts
            </span>
            ：2022/3/1(火) 07:55:19<small>.08</small> ID:mnc0inFlw0
            <span className="count">[3/4]</span>
          </dt>
          <dd>
            <p>
              <img src="/img/how-to-mint.svg" alt="How to mint" />
              <br />
              Please enter the quantity to purchase and click the PURCHASE
              button.
              <br />
              <br />
              Discounts will be applied if you purchase 10 or more at the same
              time.
              <br />
              ==============================
            </p>
            <div className="purchase-area">
              <img src="/img/sample.png" alt="NFT sample" />
              <div className="nft-info">
                <span className="nft-title">MONA Flower</span>{" "}
                <span>
                  <span className="nft-price">
                    {ethers.utils.formatEther(salePrice)}
                  </span>{" "}
                  ETH{" "}
                  <span className="nft-remaining">
                    ({remainingAmount} / 9870)
                  </span>
                </span>
              </div>
              <div>
                {!account ? (
                  <>
                    <p>
                      <button
                        className="connect-wallet-button"
                        onClick={() => activateBrowserWallet()}
                      >
                        Connect Wallet
                      </button>
                    </p>
                  </>
                ) : (
                  <div className="order-wrapper">
                    {mintState.status === "Success" ? (
                      <></>
                    ) : (
                      <span className="order-quantity">
                        <span>the order quantity:</span>
                        <input
                          className="order-input"
                          type="number"
                          value={amount}
                          min="1"
                          max={remainingAmount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      </span>
                    )}

                    {purchaseButton()}
                  </div>
                )}
              </div>
              {!window.ethereum?.isMetaMask && (
                <>
                  <button
                    className="metamask-app-button"
                    onClick={() => {
                      window.open(
                        `https://metamask.app.link/dapp/${window.location.host}/#/`
                      );
                    }}
                  >
                    Open Metamask App
                  </button>
                </>
              )}
              <p>Holders can have only NFT itself.</p>
              <p>
                (Items having rarity will be sold in an auction at a later
                date.)
              </p>
            </div>
          </dd>
          <br />
        </dl>
        <dl>
          <dt className="item">
            4 ：
            <span className="name sage" onClick={onClickName}>
              名無しさん＠NFT
            </span>
            ：2022/3/1(火) 08:12:48<small>.10</small> ID:d9eXth0u0
          </dt>
          <dd>
            <p>
              <Link to="1" smooth={true} duration={600}>
                <span className="jump-link">&gt;&gt;1</span>
              </Link>
            </p>
            <img src="/img/otsu.svg" alt="ichi otsu" />
          </dd>
          <br />
        </dl>
        <dl id="giveaway">
          <dt className="item">
            5 ：
            <span className="name sage" onClick={onClickName}>
              2chpool◆bzJIFpVqts
            </span>
            ：2022/3/1(火) 08:21:45<small>.23</small> ID:m4vRdi2cP
            <span className="count">[4/4]</span>
          </dt>
          <dd>
            <p>
              <img src="/img/giveaway.svg" alt="giveaway" />
              <br />
              To join giveaway, click on the Tweet button below and tweet with
              your Ethereum address.
              <br />
              <br />
              Winners: 5<br />
              Deadline: Friday, March 4, 2022 23:59
              <br />
              <br />
              MONA Flower will be transfer directly to the winners.
              <br />
              <br />
              <button className="share-button" onClick={onTweetButtonClick}>
                <img className="twitter-logo" src={logoImg} alt="twitter" />
                Tweet
              </button>
            </p>
          </dd>
          <br />
        </dl>
        <dl>
          <dt className="item">
            6 ：
            <span className="name sage" onClick={onClickName}>
              名無しさん＠NFT
            </span>
            ：2021/3/1(日) 08:40:32<small>.19</small> ID:QwQtthy9P
          </dt>
          <dd>
            <p>
              Links
              <br />
              <br />
              ◼︎Etherscan
              <br />
              <a href={etherScanUrl} target="_blank" rel="noreferrer noopener">
                {etherScanUrl}
              </a>
              <br />
              ◼OpenSea Collection
              <br />
              <a href={openSeaUrl} target="_blank" rel="noreferrer noopener">
                {openSeaUrl}
              </a>
              <br />
              ◼MONACOIN
              <br />
              <a
                href="https://monacoin.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                https://monacoin.org/
              </a>
            </p>
          </dd>
          <br />
        </dl>
        <dl>
          <dt className="item">
            7 ：<span className="name">名無しさん＠NFT</span>：2021/3/1(火)
            08:58:09<small>.44</small> ID:lkHgtF7YP
          </dt>
          <dd>
            <p>
              <img src="/img/age.svg" alt="age" />
            </p>
          </dd>
        </dl>
        <hr />
        <div style={{ textAlign: "center" }}>MONA Flower</div>
      </div>
    </main>
  );
}

export default App;

console.log(`
_____ ______   ________  ________   ________          ________ ___       ________  ___       __   _______   ________     
|\\   _ \\  _   \\|\\   __  \\|\\   ___  \\|\\   __  \\        |\\  _____\\\\  \\     |\\   __  \\|\\  \\     |\\  \\|\\  ___ \\ |\\   __  \\    
\\ \\  \\\\\\__\\ \\  \\ \\  \\|\\  \\ \\  \\\\ \\  \\ \\  \\|\\  \\       \\ \\  \\__/\\ \\  \\    \\ \\  \\|\\  \\ \\  \\    \\ \\  \\ \\   __/|\\ \\  \\|\\  \\   
 \\ \\  \\\\|__| \\  \\ \\  \\\\\\  \\ \\  \\\\ \\  \\ \\   __  \\       \\ \\   __\\\\ \\  \\    \\ \\  \\\\\\  \\ \\  \\  __\\ \\  \\ \\  \\_|/_\\ \\   _  _\\  
  \\ \\  \\    \\ \\  \\ \\  \\\\\\  \\ \\  \\\\ \\  \\ \\  \\ \\  \\       \\ \\  \\_| \\ \\  \\____\\ \\  \\\\\\  \\ \\  \\|\\__\\_\\  \\ \\  \\_|\\ \\ \\  \\\\  \\| 
   \\ \\__\\    \\ \\__\\ \\_______\\ \\__\\\\ \\__\\ \\__\\ \\__\\       \\ \\__\\   \\ \\_______\\ \\_______\\ \\____________\\ \\_______\\ \\__\\\\ _\\ 
    \\|__|     \\|__|\\|_______|\\|__| \\|__|\\|__|\\|__|        \\|__|    \\|_______|\\|_______|\\|____________|\\|_______|\\|__|\\|__|
`);
console.log(`
 ∧＿∧    ／￣￣￣￣￣￣
（ ´∀｀） ＜   ｵﾏｴﾓﾅ-
（     ）  ＼＿＿＿＿＿＿
｜ ｜ |
（_＿)＿）
`);
