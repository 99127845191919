import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChainId } from "@usedapp/core";
import { DAppProvider } from "@usedapp/core";

const config = {
  readOnlyChainId:
    process.env.REACT_APP_ID === "80001" ? ChainId.Mumbai : ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      "https://mainnet.infura.io/v3/d489fde73cdf438ebda91b3068d202c3",
    [ChainId.Mumbai]: "https://matic-mumbai.chainstacklabs.com",
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
