import { Contract, ethers } from "ethers";
import { useCall } from "@usedapp/core";
import { nftABI } from "../nftABI";

export function useSaleId(nftAddress) {
  const { value, error } =
    useCall(
      nftAddress && {
        contract: new Contract(nftAddress, new ethers.utils.Interface(nftABI)),
        method: "saleId",
        args: [],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}
